import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import TryOnItem from "./OptionButton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TryOnIcons from "./IconSlider";

export default function TryOnSlider({
  api_data,
  productType,
  modifySubCategory,
}) {
  const settings = {
    variableWidth: true,
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    swipeToSlide: true,

    touchThreshold: 20,
    speed: 150,
    afterChange: afterChangeOptionName,
  };
  const sliderRef = useRef();
  const [user, setUser] = useState(0);
  const [currentType, setcurrentType] = useState(0);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current?.innerSlider?.changeSlide(
        { message: "index", index: 0 },
        true
      );
      setUser(0);
      setcurrentType(0);
    }
  }, [productType]);

  function afterChangeOptionName(current) {
    setUser(current);
    modifySubCategory(current);
    setcurrentType(0);
  }

  return (
    <div id="try-on-carousel">
      <TryOnIcons
        stateValue={user}
        api_data={api_data}
        currentType={currentType}
        setcurrentType={setcurrentType}
        productType={productType}
      />

      <div id="option-slider-div">
        <Slider {...settings} ref={sliderRef}>
          {api_data.map((eachvalue, index) => {
            return <TryOnItem key={index} name={eachvalue.display} />;
          })}
        </Slider>
        <h1 className="dot">.</h1>
      </div>
    </div>
  );
}
// }
