import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState, useRef } from "react";
import TypeButton from "./TypeButton";
import styled from "styled-components";
import IconButton from "./IconButton";
import { PRODUCT_TYPES } from "../constants";

const TypeButtonContainer = styled.div`
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-left: 100px;
`;

export default function TryOnIcons({
  stateValue,
  api_data,
  currentType,
  setcurrentType,
  productType,
}) {
  let slickSliderRef = useRef();

  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  const getSkus = () => {
    const stateData = api_data?.[stateValue];

    if (!stateData) return [];

    return stateData.tabs
      ? stateData.tabs?.[currentType]?.skus || []
      : stateData.skus || [];
  };

  const skus = getSkus();

  const applyBySku = (sku) => {
    window.GlamAR.applyBySku(sku);
  };

  const handleTryOnIconChange = (current) => {
    setCurrentIconIndex(current);
    const sku = skus[current]?.id;
    if (sku) {
      applyBySku(sku);
    }
  };

  const afterTryOnItemStateChange = () => {
    if (currentIconIndex === 0 && skus.length > 0) {
      const sku = skus[0].id;

      applyBySku(sku);
    } else {
      slickSliderRef.current?.slickGoTo(0, false);
      handleTryOnIconChange(0);
      setCurrentIconIndex(0);
    }
  };

  const settings = {
    variableWidth: true,
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 150,
    arrows: false,
    cssEase: "linear",
    swipeToSlide: true,
    touchThreshold: 20,
    afterChange: handleTryOnIconChange,
  };

  useEffect(() => {
    afterTryOnItemStateChange();
  }, [stateValue, currentType, productType]);

  function handleTypeButtonClick(index) {
    setcurrentType(index);
  }

  const renderTypeButtons = () => {
    const stateData = api_data?.[stateValue];
    if (!stateData || !stateData.tabs) return null;

    return stateData.tabs.map((type, index) => (
      <TypeButton
        key={index}
        typeName={type.name}
        setIconsOrColors={() => handleTypeButtonClick(index)}
        currentButton={currentType}
        keyValue={index}
      />
    ));
  };

  return (
    <>
      <div id="icon-slider-div">
        <img
          className="icon-center-dash"
          src="./images/rectangle.png"
          alt="rectangle"
        />

        <div className="icon-blur-background"></div>

        <Slider {...settings} ref={slickSliderRef}>
          {skus.map((sku, index) => (
            <IconButton
              imgUrl={sku?.imgUrl || null}
              key={index}
              color={sku?.color}
              isHair={api_data?.[stateValue]?.name === "hair"}
            />
          ))}
        </Slider>
      </div>
      <TypeButtonContainer>{renderTypeButtons()}</TypeButtonContainer>
    </>
  );
}
// }
