import TryOnSlider from "./Components/TryOnCarousel";
import "./App.css";
import { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { useSelector } from "react-redux";
import Dropdown from "./Components/ModelDropDown/Dropdown";
import { AccessoriesSubcategory, dummyData, PRODUCT_TYPES } from "./constants";

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: white;
`;

const UIWrapper = styled.div`
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  display: ${(props) => (props.show ? "flex" : "none")};
`;
const API_KEY = "ff4146c9-386a-463d-9b7d-4191bfa35c7f";

function App() {
  const [isUiSliderState] = useSelector((state) => [
    state.sdkTabsData.isUiSliderState,
  ]);

  const [customData, setcustomData] = useState([]);
  const [productType, setProductType] = useState(PRODUCT_TYPES.MAKEUP);
  const [subCategory, setSubCategory] = useState(
    dummyData[productType][0].name
  );

  //currentAccessoryCategoryNameRef is to differenciate between cap at the time of sdk initilization so that hat and cap dont have name conflicts as both have same sub-categroy name
  const currentAccessoryCategoryNameRef = useRef(
    dummyData[PRODUCT_TYPES.ACCESSORIES][0].categoryName
  );

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const sdkIframeRef = useRef(null);
  const previousSubcategoryRef = useRef(dummyData[productType][0].name);

  const ToggleDropDown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  const handleModifyProductType = (type) => {
    setProductType(type);
    setcustomData(dummyData[type]);
    setSubCategory(dummyData[type][0]?.name);
    if (type === PRODUCT_TYPES.ACCESSORIES) {
      currentAccessoryCategoryNameRef.current = dummyData[type][0].categoryName;
    }
  };

  const modifySubCategory = (index) => {
    setSubCategory(dummyData[productType][index]?.name);

    if (productType === PRODUCT_TYPES.ACCESSORIES) {
      currentAccessoryCategoryNameRef.current =
        dummyData[productType][index]?.categoryName;
    }
  };
  const clearSDKFrame = () => {
    return new Promise((resolve, reject) => {
      if (sdkIframeRef.current) {
        sdkIframeRef.current.innerHTML = "";
        resolve();
      } else {
        reject(new Error("sdk element not found"));
      }
    });
  };

  const intialiseSDK = (productType, category) => {
    console.log(
      "initialise SDK --------------------------- ",
      productType,
      category
    );
    clearSDKFrame()
      .then(() => {
        window.GlamAR.init("sdk-iframe", API_KEY, {
          mode: "private",
          platform: "web",
          category: category,
        });
        window.GlamAR.addEventListener("*", (e) => {
          switch (e) {
            case "loaded":
              console.log("event loaded");
              if (productType === PRODUCT_TYPES.MAKEUP) {
                window.GlamAR.applyBySku(
                  dummyData[productType][0]?.tabs[0]?.skus[0]?.id
                );
              } else {
                const categoryIndex =
                  productType === PRODUCT_TYPES.ACCESSORIES
                    ? AccessoriesSubcategory[
                        currentAccessoryCategoryNameRef.current
                      ]
                    : 0;

                window.GlamAR.applyBySku(
                  dummyData[productType][categoryIndex]?.skus[0]?.id
                );
              }

              break;
            case "init-complete":
              break;
            case "sku-applied":
              break;
            case "sku-failed":
              break;
            default:
              console.log("na");
          }
        });
        //setting Previous subcaterogy so that sdk initilization is needed only when subcategory is changed to hair, nail or changed form hair,nails in makeup
        previousSubcategoryRef.current = subCategory;
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    setcustomData(dummyData[productType]);
    intialiseSDK(productType, subCategory);
  }, [productType]);

  useEffect(() => {
    if (
      productType === PRODUCT_TYPES.ACCESSORIES ||
      subCategory === "hair" ||
      subCategory === "nail" ||
      previousSubcategoryRef.current === "nail" ||
      previousSubcategoryRef.current === "hair"
    ) {
      intialiseSDK(productType, subCategory);
    }
  }, [subCategory]);

  return (
    <Wrapper>
      <Dropdown
        isOpen={isDropDownOpen}
        options={Object.values(PRODUCT_TYPES)}
        ToggleDropDown={ToggleDropDown}
        selectCallback={handleModifyProductType}
        value={productType}
      />
      <SdkFrame sdkIframeRef={sdkIframeRef} />

      <UIWrapper show={isUiSliderState}>
        <TryOnSlider
          api_data={customData}
          productType={productType}
          modifySubCategory={modifySubCategory}
        />
      </UIWrapper>
    </Wrapper>
  );
}

function SdkFrame({ sdkIframeRef }) {
  return (
    <>
      <div id="sdk-iframe" ref={sdkIframeRef}></div>
    </>
  );
}

export default App;
