import React, { useState } from "react";
import styled from "styled-components";
import DownArrow from "../../assets/down-arrow.svg";

const SelectedItem = styled.div`
  padding: 13px 0px 13px 12px;
  /* width: 200px; */
  flex: 1 0 0;
  color: var(--Text-Colour-Heading, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 25.2px */
`;

const Wrapper = styled.div`
  display: flex;
  padding-left: 12px;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  z-index: 2000;
  position: absolute;
  top: 10px;
  left: 50px;
  width: 160px;
  flex-direction: column;

  .tile-selector {
    display: flex;
    flex-direction: row;
    min-width: 24px;
    /* width: 160px; */
    height: 40px;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    padding: 2px;
    border-radius: var(--Radius-8px-Radius, 8px);
    border: 1px solid var(--Grey-Tone-Grey-20, #404141);
    background: var(--Surface-Colour-Surface-03, #343536);
    cursor: pointer;

    img {
      padding-right: 12px;
    }

    @media (max-width: 768px) {
      height: 30px;
    }
  }

  .down-menu {
    top: 53px;
    z-index: 100;
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    /* width: 160px; */
    border-radius: 8px;
    border: 1px solid var(--Surface-Colour-Surface-05, #585859);
    background: var(--Surface-Colour-Surface-03, #343536);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    margin-top: 5px;
  }

  .dropdown-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    /* width: 150px; */
    height: 43px;
    padding: 8px 8px 8px var(--12, 12px);
    /* align-items: center; */
    gap: var(--4, 4px);
    border-bottom: 1px solid var(--Grey-Tone-Grey-20, #404141);
    color: var(--Text-Colour-Body-01, #b7b8b8);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    cursor: pointer;

    @media (max-width: 768px) {
      height: 30px;
    }
  }

  .dropdown-list:hover {
    color: var(--white);
    border-bottom: 1px solid var(--white);
  }

  @media (max-width: 1024) {
    left: 200px;
    /* width: ; */
  }
  @media (max-width: 768px) {
    left: 100px;
    /* width: ; */
  }
`;

const Dropdown = ({
  isOpen,
  options,
  ToggleDropDown,
  value,
  selectCallback,
}) => {
  const [selectedItem, setSelectedItem] = useState(value || "");

  const handleSelect = (option) => {
    setSelectedItem(option);
    selectCallback(option);
    ToggleDropDown();
  };
  return (
    <Wrapper>
      <div className="tile-selector" onClick={ToggleDropDown}>
        <SelectedItem>
          {selectedItem.charAt(0).toUpperCase() + selectedItem.slice(1) ||
            options[0]}
        </SelectedItem>
        <img src={DownArrow} alt="down-arrow" onClick={ToggleDropDown} />
      </div>
      {isOpen && (
        <div className="down-menu">
          {options &&
            options.map((option, index) => {
              return (
                <div
                  key={index}
                  className="dropdown-list"
                  onClick={() => handleSelect(option)}
                >
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </div>
              );
            })}
        </div>
      )}
    </Wrapper>
  );
};

export default Dropdown;
