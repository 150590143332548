export const PRODUCT_TYPES = {
  MAKEUP: "makeup",
  EYEWEAR: "eyewear",
  ACCESSORIES: "accessories",
};

export const AccessoriesSubcategory = {
  ring: 0,
  bracelet: 1,
  watch: 2,
  earring: 3,
  cap: 4,
  mangtika: 5,
  hat: 6,
  necklace: 7,
};

export const dummyData = {
  [PRODUCT_TYPES.MAKEUP]: [
    {
      name: "lipstick",
      display: "Lipstick",
      tabs: [
        {
          name: "Matte",
          skus: [
            {
              id: "00b0da0e-83fe-47d3-b6a1-f438bcabee49",
              color: "#ff0080",
            },
            {
              id: "403e3db6-1c71-4e83-9d70-42b3f6215c81",
              color: "#7f2929",
            },
            {
              id: "90b8bb5a-848d-463f-b900-3c17552e3c2c",
              color: "#7f0652",
            },
            {
              id: "4d03da05-1bc1-42b8-94d9-d7289c74b5b1",
              color: "#7f2929",
            },
            {
              id: "df4b1281-ca04-446d-a45b-bc316b130735",
              color: "#7f0652",
            },
            {
              id: "109707c7-6c72-4da9-8258-81dbe4991172",
              color: "#a30f43",
            },
            {
              id: "9eda4a93-1daa-4ed5-8277-f9da77a7a3b4",
              color: "#5f1111",
            },
          ],
        },
        {
          name: "Gloss",
          skus: [
            {
              id: "5013c3da-b339-4dba-8c21-b46152bbc79a",
              color: "#ff0080",
            },
            {
              id: "e76d3368-6704-4d34-a741-071907a1d001",
              color: "#7f2929",
            },
            {
              id: "cfa2d4e8-26c1-45e8-9a06-9218d3be2839",
              color: "#7f0652",
            },
            {
              id: "86040ef5-065b-4dee-bc37-215461dd5572",
              color: "#7f2929",
            },
            {
              id: "43cd46d2-9d4b-468e-9e21-ec2260d4df7d",
              color: "#7f0652",
            },
            {
              id: "2b5bffcd-9526-46df-8d8e-67ea18392b88",
              color: "#a30f43",
            },
            {
              id: "7beb7c59-a920-432d-89d4-6ff1313e971a",
              color: "#5f1111",
            },
          ],
        },
        {
          name: "Shimmer",
          skus: [
            {
              id: "15d5c6db-0d9a-41af-b2fe-896442456846",
              color: "#ff0080",
            },
            {
              id: "4865866d-7bb8-41b7-83a1-5542f7d8b1ce",
              color: "#7f2929",
            },
            {
              id: "ef98813b-20b8-4c47-adfe-ac54748129bc",
              color: "#7f0652",
            },
            {
              id: "f492a54c-dbd2-4ab6-aac1-2e8e1fe6aa1f",
              color: "#7f2929",
            },
            {
              id: "bcc5d7c8-1b21-4096-be08-b7d684f294bd",
              color: "#7f0652",
            },
            {
              id: "a7df56f6-de22-46be-bad6-e105d7b7d4fa",
              color: "#a30f43",
            },
            {
              id: "41cc077f-b2f5-4505-9fcc-83ed9dad2721",
              color: "#5f1111",
            },
          ],
        },
      ],
    },
    {
      name: "eyeshadow",
      display: "Eyeshadow",
      tabs: [
        {
          name: "matte",
          skus: [
            {
              id: "b6f595f8-3adc-40a5-980e-009b5a14cfd0 ",
              color: "#C06B61",
            },
            {
              id: "453ec1b3-95b6-454e-9380-46d9de9fa031",
              color: "#A15586",
            },
            {
              id: "a4104346-68f2-4b89-8a37-6e194ea2058c",
              color: "#5A544E",
            },
            {
              id: "e5d1e075-2590-43c1-9f21-467a6ccdcf10",
              color: "#FECE34",
            },
            {
              id: "b4ac7611-98ea-409f-9f76-70f5897026cf",
              color: "#DC8192",
            },
          ],
        },
        {
          name: "Shimmer",
          skus: [
            {
              id: "1b97765a-7098-43a9-a862-e18eb75f1e47",
              color: "#16497D",
            },
            {
              id: "0de75064-aaf5-4132-963a-c4b298838c51",
              color: "#A25587",
            },
            {
              id: "99c5fa8b-2ff9-4496-9a5b-0da48603bd03",
              color: "#BF8D79",
            },
            {
              id: "e6ae7dd4-7548-4d06-a408-46c64d09d5d9",
              color: "#272026",
            },
            {
              id: "bec09372-2d35-4317-a3d0-8234fbcc23f3",
              color: "#C76D66",
            },
          ],
        },
      ],
    },
    {
      name: "blush",
      display: "Blush",
      tabs: [
        {
          name: "shimmer",
          skus: [
            {
              id: "dc2d3ae9-058a-48bf-a945-ef077b6f7f95",
              color: "#E2978C",
            },
            {
              id: "bd28f53b-cd83-4c9a-a97c-64b2ed44f828",
              color: "#D88F75",
            },
            {
              id: "76f434a5-6938-42d9-a5ad-528ea07886c4",
              color: "#CE838A",
            },
            {
              id: "5d86b3bb-96a5-471e-84e2-4a29b0256200",
              color: "#EA5869",
            },
            {
              id: "070f8962-072d-4516-a9e7-bdb5bc589515",
              color: "#E1968B",
            },
          ],
        },
      ],
    },
    {
      name: "eyeliner",
      display: "Eyeliner",
      tabs: [
        {
          name: "matte",
          skus: [
            {
              id: "b564961d-e51e-4aca-a62c-6a46a80222ca",
              color: "#E24692",
            },
            {
              id: "45f16b86-3720-4e7e-980f-085a9d991cfc",
              color: "#C85150",
            },
            {
              id: "c9da45ae-e582-491f-bd0d-f1945673621c",
              color: "#B5001F",
            },
            {
              id: "9715c927-b105-4ee2-899e-4c8300aec45d",
              color: "#D14E76",
            },
            {
              id: "43d27902-5c63-46d3-8a16-9db55477fb61",
              color: "#70213E",
            },
          ],
        },
      ],
    },
    {
      name: "hair",
      display: "Hair Color",
      tabs: [
        {
          name: "glossy",
          skus: [
            {
              id: "345e7ea2-cebd-411f-bf95-9ea85e8eb627",
              color: "#ffd9b3",
            },
            {
              id: "216559ce-5611-4a21-958a-9296ecc11803",
              color: "#9c5249",
            },
            {
              id: "d64a9540-5dfb-4c99-8ce6-06f85f883e4d",
              color: "#ff66d9",
            },
            {
              id: "75a1ce86-c585-4933-a0c5-19722a735a17",
              color: "#b55239",
            },
            {
              id: "92a40f13-3457-47d9-8652-5d26840c2ab9",
              color: "#33bbff",
            },
            {
              id: "21696ec8-c5f2-4a07-b4d5-4352cac45071",
              color: "#3b3024",
            },
          ],
        },
      ],
    },
  ],

  [PRODUCT_TYPES.EYEWEAR]: [
    {
      name: "glasses",
      display: "Eyeglasses",
      skus: [
        {
          id: "fdcca684-714d-4b6b-a145-a07cd081af90",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/eyeglasses/01.png",
        },
        {
          id: "0b5b4633-7222-4077-b04a-1c579b7be8bd",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/eyeglasses/02.png",
        },
        {
          id: "975c7cd6-7f14-43c4-b806-9665f4015409",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/eyeglasses/03.png",
        },
        {
          id: "cb46e0c4-16af-473c-9eab-63f5d4f312d3",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/eyeglasses/04.png",
        },
        {
          id: "98616260-e274-4379-8c8a-54476dc2e67f",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/eyeglasses/05.png",
        },
      ],
    },
    {
      name: "glasses",
      display: "Sunglasses",
      skus: [
        {
          id: "a2f87614-edd4-4d4c-8cde-9a6bc0e8ef24",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/01.png",
        },
        {
          id: "93c8ec4f-5ec9-499a-b9e3-db9e641566b2",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/02.png",
        },
        {
          id: "8ed62c76-ac13-4220-a3bc-385481aa38e0",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/07.png",
        },
        {
          id: "3e7f332d-65aa-49d3-b82c-ba544c45e7aa",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/04.png",
        },
        {
          id: "32cebd68-710c-4103-afb4-17dd828bcfd4",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/05.png",
        },
      ],
    },
  ],

  //categoryName is to differenciate between cap at the time of sdk initilization so that hat and cap dont have name conflicts as both have same sub-categroy name
  [PRODUCT_TYPES.ACCESSORIES]: [
    {
      name: "ring",
      display: "Rings",
      categoryName: "ring",
      skus: [
        {
          id: "b63d58bf-bfc4-4d60-a9ea-e90c8cf344aa",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e2d265d26c6d904aeeaebf_01.avif",
        },
        {
          id: "531da23b-d1d7-4c98-a40c-627365c3e189",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e2922e7ae6cf56f4e01189_02.avif",
        },
        {
          id: "be99ee3c-ef83-43c0-9b4c-9c9ddce62d1d",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e2926c123912ceebef72e3_03.avif",
        },
        {
          id: "caf911ac-d261-4b51-a484-17a09086881f",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e292a0211ead293427802a_04.avif",
        },
        {
          id: "ac074303-bec4-4e40-89bf-09fd575fae46",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e2c76a8b1d225981a76bdf_05.1.avif",
        },
      ],
    },
    {
      name: "bracelet",
      display: "Bracelets",
      categoryName: "bracelet",
      skus: [
        {
          id: "fbb37151-d09b-4156-a93f-1c06cc5d1e04",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e1d262449469f5dc8ef6dc_01.avif",
        },
        {
          id: "faa06c7e-9f0c-4f3d-a72c-ef449dea4889",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e1d2f88ec7c0f6acf08128_02.avif",
        },
        {
          id: "60b2a9b5-1d99-4f59-99e8-b3017c41b1c6",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e1d328284e2b1835e80a7f_03.avif",
        },
        {
          id: "a564c414-b43c-4d8d-a5e1-5731d5197eb6",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e1d389b6fdf48ff5fa3e80_05.avif",
        },
        {
          id: "ebdc1cc4-5def-4454-b828-d89c30bf5e36",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e1d35910be90ddda4380d6_04.avif",
        },
      ],
    },
    {
      name: "watch",
      display: "Watches",
      categoryName: "watch",
      skus: [
        // {
        //     id: "7001eea1-e83c-4702-9f9e-99ee3eea405b",
        //     imgUrl: "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/667123ad86370a1578710764/669925e3af176360f0e425ff_Watches%2001.svg",
        // },
        {
          id: "efd4fac2-c01a-4440-b668-4800b4717249",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/watch/watch_02.png",
        },
        {
          id: "cfba6b91-bc0f-47d4-8c0e-f762c59d603b",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/watch/watch_03.png",
        },
        {
          id: "6b7cfa81-8090-43c6-a234-fc5288feda8c",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/watch/watch_04.png",
        },
        {
          id: "b1af021a-3cec-4367-be57-30d53fefcb59",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/watch/watch_05.png",
        },
      ],
    },
    {
      name: "earring",
      display: "Earring",
      categoryName: "earring",
      skus: [
        {
          id: "d9df0dbc-3c68-4ecc-a6d3-c201bdf5bf7b",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/earring/earring_01.png",
        },
        {
          id: "4e38b1be-e120-44f9-8db4-8bb797c80b2a",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/earring/earring_02.png",
        },
        {
          id: "fddd7f42-bca0-4b62-a456-61c41fd9b50f",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/earring/earring_03.png",
        },
        {
          id: "16a38360-f6c2-4c74-97df-8d26bca9e979",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/earring/earring_05.png",
        },
        {
          id: "f8fdf93b-1b94-44d4-b5c7-8d11729fc5ca",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/earring/earring_04.png",
        },
      ],
    },
    {
      name: "cap",
      display: "Caps",
      categoryName: "cap",
      skus: [
        {
          id: "80c6a1fe-d057-4719-8bf7-b054b8702ca4",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/cap/01.png",
        },
        {
          id: "ca8be6f1-4293-43ec-8b7a-d93ad64f946c",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/cap/02.png",
        },
        {
          id: "5480746f-3e57-4082-89dd-d20fb27c7f2a",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/cap/03.png",
        },
        {
          id: "71864b08-da9a-4df8-916c-565aa6eb84e8",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/cap/04.png",
        },
        {
          id: "059aac1d-18a1-42ae-a632-76fc17d6dd4e",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/cap/05.png",
        },
      ],
    },
    {
      name: "mangtika",
      display: "Maangtikka",
      categoryName: "mangtika",
      skus: [
        {
          id: "ef6b28a7-5a20-4716-a5f2-da1d61f0e40b",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e1aa9faed048ec9940c14c_01.avif",
        },
        {
          id: "999f64af-2589-4f8d-b0c3-7464c84af3db",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e1ab72aeef8f6d5570675a_02.avif",
        },
        {
          id: "5705d833-a1b6-448f-8f4a-916435167bc8",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e1abac89edd19ecd8b05e0_03.avif",
        },
        {
          id: "91312378-8a6d-4957-b08e-5d15d6e0aa0a",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/66751ebd9740f5d5704a4b56/66e1abf0d280f17d2f3313bf_04.avif",
        },
        {
          id: "dfecbbf0-a1f0-45af-a5ae-bb7542a8a156",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/667123ad86370a1578710764/66a11ebf8b89e1fa9ec77e71_maang_05.avif",
        },
      ],
    },
    {
      name: "cap",
      display: "Hats",
      categoryName: "hat",
      skus: [
        {
          id: "4ccdf377-ee31-4715-b564-229802025a19",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/hat/01.png",
        },
        {
          id: "c403da4e-dac4-4ddc-8f56-4596b7ba8b9f",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/hat/02.png",
        },
        {
          id: "2e64d6d1-070e-46d6-b03b-2436cd228ad1",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/hat/03.png",
        },
        {
          id: "d16280a5-d0b7-4f01-ad67-21431860e982",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/hat/04.png",
        },
        {
          id: "afcd66e9-e9f4-498e-a4c6-2d004678967a",
          imgUrl:
            "https://cdn.pixelbin.io/v2/glamar-fynd-835885/original/Demo_Store/Icon_image/hat/05.png",
        },
      ],
    },
    {
      name: "necklace",
      display: "Necklace",
      categoryName: "necklace",
      skus: [
        {
          id: "85745081-7a4d-4a74-ae96-f0237ad3dc4b",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/667123ad86370a1578710764/66992516af176360f0e378dd_Necklase%2001.svg",
        },
        {
          id: "696a675a-4118-495d-bd14-c086b4486408",
          imgUrl:
            "https://cdn.pixelbinz0.de/v2/calm-term-553038/fCRx13/original/667123ad86370a1578710764/66992516a8bde6794bf9adf6_Necklase%2002.svg",
        },
      ],
    },
  ],
};
